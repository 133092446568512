import React from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { NotFoundPage } from "views/NotFound"
import { RootLayout } from "views/RootLayout"
import { ChoosePathView } from "./views/choosePath/ChoosePathView"
import { StationView } from "./views/stationView/StationView"
import { VehicleChooserView } from "./views/vehicleChooserView/VehicleChooserView"

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      { path: "*", element: <NotFoundPage /> },
      {
        path: "/",
        element: <StationView />,
      },
      {
        path: "/:station/vehicle",
        element: <VehicleChooserView />,
      },
      {
        path: "/:station/vehicle/:vehicle/drive",
        element: <ChoosePathView />,
      },
    ],
  },
])

export const App = (): React.JSX.Element => {
  return <RouterProvider router={router} />
}
