import { useAuth } from "contexts/AuthContext"
import { Client } from "lib/api/client"
import React, { createContext, useContext } from "react"

export const Context = createContext<Client | null>(null)

type ApiContextProps = {
  children: React.ReactNode
}

export const ApiContext = ({ children }: ApiContextProps): React.JSX.Element => {
  const { user } = useAuth()
  if (user === null || user === undefined) {
    throw new Error("user can't be null in ApiContext")
  }
  const credentials = {
    email: user.email,
    name: user.displayName,
    operatorSession: makeSessionId(user.uid),
    user,
  }
  const client = new Client().withCredentials(credentials)
  return <Context.Provider value={client}>{children}</Context.Provider>
}

export const useApi = (): Client => {
  const client = useContext(Context)
  if (client === null) {
    throw new Error("useApi can only be called inside ApiContext")
  }
  return client
}

export const makeSessionId = (uid: string): string => {
  const sessionIdLength = 32
  let id = uid.substring(0, sessionIdLength)
  const diff = sessionIdLength - id.length
  for (let i = 0; i < diff; i += 1) {
    id = id.concat("=")
  }
  return id
}
