import { Caption } from "@einride/ui"
import Ajv from "ajv"
import { Feature, FeatureCollection, LineString, Point } from "geojson"
import React, { ReactElement } from "react"
import { GeoJsonFeatureCollectionSchema } from "../../assets/FeatureCollection"

const filterPath = (path: FeatureCollection<LineString | Point>): Feature<LineString>[] => {
  return path.features.filter((f) => f.geometry.type === "LineString") as Feature<LineString>[]
}

const distance = (lamda1: number, phi1: number, lamda2: number, phi2: number): number => {
  const R = 6371000
  const deltaLamda = ((lamda2 - lamda1) * Math.PI) / 180
  const newPhi1 = (phi1 * Math.PI) / 180
  const newPhi2 = (phi2 * Math.PI) / 180
  const x = deltaLamda * Math.cos((newPhi1 + newPhi2) / 2)
  const y = newPhi2 - newPhi1
  const d = Math.sqrt(x * x + y * y)
  return R * d
}

const findMaxSpeed = (path: Feature<LineString>[]): number => {
  return path.reduce(
    (maxSpeed, f) => Math.max(maxSpeed, f.properties?.maxSpeedMetresPerSecond ?? 0),
    0,
  )
}

const calculateLength = (path: Feature<LineString>[]): number => {
  return path
    .map((f) => {
      let result = 0
      for (let i = 1; i < f.geometry.coordinates.length; i += 1) {
        result += distance(
          f.geometry.coordinates[i - 1][0],
          f.geometry.coordinates[i - 1][1],
          f.geometry.coordinates[i][0],
          f.geometry.coordinates[i][1],
        )
      }
      return result
    })
    .reduce((agg, val) => agg + val, 0)
}

type PathProps = { path: FeatureCollection<LineString | Point> }

export const PathMaxSpeed = ({ path }: PathProps): ReactElement => {
  const mpsToKph = 3.6
  const mpsToMph = 3600 / 1609.34
  const maxSpeedMps = findMaxSpeed(filterPath(path))
  const speedText = `Max speed: ${(maxSpeedMps * mpsToKph).toFixed(2)} kph / 
  ${(maxSpeedMps * mpsToMph).toFixed(2)} mph`
  return <Caption color="secondary">{speedText}</Caption>
}

export const PathLength = ({ path }: PathProps): ReactElement => {
  const metersToMiles = 1 / 1609.34
  const lengthMeters = calculateLength(filterPath(path))
  const lengthText = `Distance: ${lengthMeters.toFixed(2)} m / ${(
    lengthMeters * metersToMiles
  ).toFixed(2)} mi`
  return <Caption color="secondary">{lengthText}</Caption>
}

export const VerifyGeoJSONFeatureCollection = (fc: FeatureCollection): void => {
  const ajv = new Ajv()
  const validate = ajv.compile(GeoJsonFeatureCollectionSchema)
  validate(fc)

  if (validate.errors) {
    throw new Error(`Schema validation error:${ajv.errorsText(validate.errors, { dataVar: "" })}`)
  }
}
