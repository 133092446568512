import { getConfig } from "constants/constants"
import { PrimaryButton, Title1 } from "@einride/ui"
import styled from "@emotion/styled"
import { FirebaseApp, FirebaseOptions, getApps, initializeApp } from "firebase/app"
import {
  GoogleAuthProvider,
  User,
  UserCredential,
  browserSessionPersistence,
  getAuth,
  getRedirectResult,
  setPersistence,
  signInWithPopup,
} from "firebase/auth"
import React, { createContext, useContext, useEffect, useMemo, useState } from "react"

const config = getConfig()

const Context = createContext<AuthContextValue | null>(null)

let app: FirebaseApp | undefined

export type AuthContextValue = {
  user: User | undefined
  loginState: LoginState
  signOut: () => Promise<void>
  signIn: () => Promise<void>
}

export type LoginState = "successful" | "error" | "pending" | "notStarted"

type AuthProps = {
  children: React.ReactNode
}

export const AuthContext = ({ children }: AuthProps): React.JSX.Element => {
  const [user, setUser] = useState<User | undefined>()
  const [loginState, setLoginState] = useState<LoginState>("notStarted")
  initFirebaseApp()
  const auth = getAuth(app)

  useEffect(() => {
    return auth.onAuthStateChanged(async (u) => {
      if (u) {
        setUser(u)
        setLoginState("successful")
      } else {
        setUser(undefined)
      }
    })
  }, [auth])

  const authContextValue = useMemo(() => {
    const login = async (): Promise<void> => {
      setLoginState("pending")
      getRedirectResult(auth)
        .then(async (result) => {
          if (result) {
            setUser(result.user)
          } else {
            await initializeGoogleLogin()
          }
        })
        .catch(() => {
          setLoginState("error")
        })
    }

    return {
      user,
      loginState,
      signOut: () => auth.signOut(),
      signIn: login,
    }
  }, [user, loginState, auth])

  return loginState === "successful" ? (
    <Context.Provider value={authContextValue}>
      <div>{children}</div>
    </Context.Provider>
  ) : (
    mkLoginPage(
      <PrimaryButton
        isLoading={loginState === "pending"}
        disabled={loginState === "pending"}
        onClick={authContextValue.signIn}
      >
        Sign in
      </PrimaryButton>,
    )
  )
}

const mkLoginPage = (button: React.ReactElement): React.JSX.Element => {
  return (
    <div>
      <Header>
        <div className="left-container">
          <Title1>Operator Auth</Title1>
        </div>
      </Header>

      <Content>
        <GoogleLoginWrapper>{button}</GoogleLoginWrapper>
      </Content>
    </div>
  )
}

const GoogleLoginWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 80%;
  height: 100%;
  text-align: center;
  align-items: center;
  button {
    width: 100%;
    height: 100px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3) !important;
    padding-left: 30px !important;
    margin: 30px 0;
  }
`

const Header = styled.div`
  background: black;
  color: white;
  padding: 24px;
  h1 {
    font-size: 300%;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 128px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: scroll;
`

const initFirebaseApp = (): void => {
  const firebaseConfig: FirebaseOptions = {
    apiKey: config.firebaseAuth.apiKey,
    authDomain: config.firebaseAuth.authDomain,
    projectId: config.firebaseAuth.projectID,
  }
  app = initializeApp(firebaseConfig)
}

export const initializeGoogleLogin = async (): Promise<UserCredential> => {
  if (!getApps.length) {
    initFirebaseApp()
  }
  const auth = getAuth(app)
  await setPersistence(auth, browserSessionPersistence)
  const provider = new GoogleAuthProvider()
  provider.setCustomParameters({
    prompt: "select_account",
    hd: "einride.tech",
  })
  return signInWithPopup(auth, provider)
}

export const useAuth = (): AuthContextValue => {
  const auth = useContext(Context)
  if (auth === null) {
    throw new Error("useAuth can only be used inside AuthContext")
  }
  return auth
}
