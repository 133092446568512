import { EinrideProvider, einrideTheme } from "@einride/ui"
import { ColorScheme } from "@einride/ui/dist/contexts/ColorSchemeProvider"
import { App } from "App"
import { ApiContext } from "contexts/ApiContext"
import { AuthContext } from "contexts/AuthContext"
import React, { StrictMode } from "react"
import { createRoot } from "react-dom/client"

const container = document.getElementById("root")
if (!container) throw new Error("Failed to find the root container")
const root = createRoot(container)

const preferredColorTheme: ColorScheme | null = localStorage.getItem(
  "preferredColorTheme",
) as ColorScheme

root.render(
  <StrictMode>
    <EinrideProvider colorMode={preferredColorTheme || "system"} theme={einrideTheme}>
      <AuthContext>
        <ApiContext>
          <App />
        </ApiContext>
      </AuthContext>
    </EinrideProvider>
  </StrictMode>,
)
