import { getConfig } from "constants/constants"
import { NewPath, NewSite, Path, Sites, Stations, User, Vehicles } from "types"

const config = getConfig()

export type Credentials = User
export class Client {
  credentials?: Credentials

  public withCredentials(c: Credentials): Client {
    this.credentials = c
    return this
  }

  public async GetPaths(parent: string): Promise<NewPath[]> {
    if (!parent) {
      return {} as Promise<NewPath[]>
    }
    return (await this.authenticatedFetch(`paths?site=${parent}`)) as Promise<NewPath[]>
  }

  public async GetPath(name: string): Promise<NewPath> {
    if (!name) {
      return Promise.reject(new Error("missing name"))
    }
    return (await this.authenticatedFetch(`path?name=${name}`)) as Promise<NewPath>
  }

  public async GetSites(): Promise<NewSite[]> {
    return (await this.authenticatedFetch("sites")) as Promise<NewSite[]>
  }

  public async GetVehicles(): Promise<Vehicles> {
    return (await this.authenticatedFetch("vehicles")) as Promise<Vehicles>
  }

  public async GetStations(): Promise<Stations> {
    return (await this.authenticatedFetch("stations")) as Promise<Stations>
  }

  public async Connect(stationId: string, vehicleId: string): Promise<Response> {
    const opts = await this.authenticatedOpts()
    opts.method = "POST"
    opts.body = vehicleId
    return fetch(`${config.backend}station/connect?station=${stationId}`, opts)
  }

  public async Disconnect(stationId: string, vehicleId: string): Promise<Response> {
    const opts = await this.authenticatedOpts()
    opts.method = "POST"
    opts.body = vehicleId
    return fetch(`${config.backend}station/disconnect?station=${stationId}`, opts)
  }

  public async ValidatePath(path: string): Promise<Response> {
    const opts = await this.authenticatedOpts()
    opts.method = "POST"
    opts.body = path
    return fetch(`${config.backend}paths/validate`, opts)
  }

  public async SetPath(stationId: string, path: Path): Promise<void> {
    const opts = await this.authenticatedOpts()
    opts.method = "POST"
    opts.body = JSON.stringify(path)
    return fetch(`${config.backend}station/path?station=${stationId}`, opts).then(
      (response): Promise<void> => {
        if (!response.ok) {
          return Promise.reject(response)
        }
        return Promise.resolve()
      },
    )
  }

  async authenticatedOpts(): Promise<RequestInit> {
    if (this.credentials === undefined || this.credentials === null) {
      return Promise.reject(new Error("no token"))
    }
    const creds = this.credentials
    const token = await creds.user.getIdToken()

    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Einride-Operator-Session": creds.operatorSession,
      },
    }
  }

  async authenticatedFetch(endpoint: string): Promise<unknown> {
    const opts = await this.authenticatedOpts()
    const response = await fetch(config.backend + endpoint, opts)
    if (response.status !== 200) {
      return response.text()
    }
    return response.json()
  }
}
