type Config = {
  backend: string
  firebaseAuth: {
    apiKey: string
    authDomain: string
    projectID: string
  }
}

const prodOld: Config = {
  backend: "https://rd-operator-backend-rpgj763qra-ew.a.run.app/",
  firebaseAuth: {
    apiKey: "AIzaSyCmkOkzwLuoMR6ntCPwubdFruKo89DEpB0", // gitleaks:allow - firebase API keys are not secrets
    authDomain: "einride-rd-operator-aet-vpn.web.app",
    projectID: "einride-aet-vpn",
  },
}

const prod: Config = {
  backend: "https://rd-operator-backend-rpgj763qra-ew.a.run.app/",
  firebaseAuth: {
    apiKey: "AIzaSyCmkOkzwLuoMR6ntCPwubdFruKo89DEpB0", // gitleaks:allow - firebase API keys are not secrets
    authDomain: "web.ro.einride.systems",
    projectID: "einride-aet-vpn",
  },
}

const dev: Config = {
  backend: "https://rd-operator-backend-bezq6jszqa-ew.a.run.app/",
  firebaseAuth: {
    apiKey: "AIzaSyC8o4_qTF86nLnbi091OEG4SqY3jnzsXtU", // gitleaks:allow - firebase API keys are not secrets
    authDomain: "einride-aet-vpn-dev.web.app",
    projectID: "einride-aet-vpn-dev",
  },
}

const local: Config = {
  backend: "http://localhost:8080/",
  firebaseAuth: {
    apiKey: "AIzaSyC8o4_qTF86nLnbi091OEG4SqY3jnzsXtU", // gitleaks:allow - firebase API keys are not secrets
    authDomain: "einride-aet-vpn-dev.web.app",
    projectID: "einride-aet-vpn-dev",
  },
}

export const configs: Record<string, Config> = {
  "web.ro.einride.systems": prod,
  "einride-rd-operator-aet-vpn.web.app": prodOld,
  "einride-aet-vpn-dev.web.app": dev,
  localhost: local,
  default: dev,
}

export const getConfig = (): Config => {
  const source = window.location.hostname

  if (configs[source]) {
    return configs[source]
  }

  return configs.default
}
