import { Banner, Box, Group, Text } from "@einride/ui"
import moment from "moment"
import React, { ReactElement } from "react"

type LastSeenWidgetProps = { time?: string }
export const LastSeenWidget = ({ time }: LastSeenWidgetProps): ReactElement => {
  const online =
    time === undefined ? false : moment(new Date(time)).isAfter(moment().subtract(5, "minutes"))

  return (
    <Box display="flex" alignItems="center">
      <Group>
        <Banner status={online ? "success" : "fail"} title={online ? "Online" : "Offline"} />
        {online && (
          <Text>
            Last seen: <br /> {moment(time).fromNow()}
          </Text>
        )}
      </Group>
    </Box>
  )
}
