import {
  Box,
  Icon,
  IconButton,
  PageLoader,
  PrimaryButton,
  SearchInput,
  Table,
  TableSkeleton,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Title1,
  Tr,
} from "@einride/ui"
import { TopRightFlexDiv } from "components/FlexDiv"
import { Logout } from "components/LogoutButton"
import { useApi } from "contexts/ApiContext"
import FuzzySearch from "fuzzy-search"
import React, { Suspense, useEffect, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { useNavigate, useParams } from "react-router-dom"
import { Vehicle } from "types"

export const VehicleChooserView = (): React.JSX.Element => {
  const client = useApi()
  const navigate = useNavigate()
  const [vehicles, setVehicles] = useState<Vehicle[] | undefined>(undefined)
  const [searchTerm, setSearchTerm] = useState("")
  const searcher = new FuzzySearch(vehicles || [], ["id", "name"])
  const filteredVehicles = searcher.search(searchTerm)
  const [sortingOrderAsc, setSortingOrderAsc] = useState<boolean>(true)
  const [sortedColumn] = useState<keyof Vehicle>("name")

  useEffect(() => {
    client.GetVehicles().then((v) => {
      setVehicles(
        Object.entries(v)
          .sort((a, b) =>
            a[1].name.localeCompare(b[1].name, undefined, { numeric: true, sensitivity: "base" }),
          )
          .map((vehicle) => {
            const [vid, vi] = vehicle
            vi.id = vid.split("/").pop() || ""
            return vi
          }),
      )
    })
  }, [client])

  const handleSearchChange = (searchString: string): void => {
    setSearchTerm(searchString)
  }

  const { station } = useParams()
  if (station === undefined) {
    throw new Error("station can not be undefined")
  }

  const th = (
    <Thead>
      <Tr color="secondary">
        <Th
          style={{ cursor: "pointer" }}
          scope="col"
          onClick={() => {
            setSortingOrderAsc(!sortingOrderAsc)
            vehicles?.reverse()
          }}
        >
          Name
          <Icon
            style={{ paddingLeft: "0.5em", paddingRight: 0 }}
            name={sortingOrderAsc ? "chevronUp" : "chevronDown"}
            hidden={sortedColumn !== "name"}
          />
        </Th>
        <Th scope="col" />
      </Tr>
    </Thead>
  )

  return (
    <ErrorBoundary fallback={<Text>Failed to load vehicles view</Text>}>
      <Suspense fallback={<PageLoader />}>
        <Box display="flex">
          <TopRightFlexDiv>
            <IconButton
              icon="arrowLeft"
              aria-label="go-back"
              onClick={() => {
                navigate("/")
              }}
            >
              {" "}
            </IconButton>
            <Logout>Log out</Logout>
          </TopRightFlexDiv>
          <Box
            alignItems="flex-start"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            padding="sm"
            height="100vh"
            width="100vw"
          >
            <Title1>Select vehicle for {station}</Title1>
            <Box padding="sm" height="100vh" width="100%">
              <SearchInput
                autoFocus
                wrapperProps={{ width: "100%" }}
                aria-label="searchVehicles"
                placeholder="Search vehicles"
                value={searchTerm}
                onInputChange={handleSearchChange}
              />
              {vehicles ? (
                <Table>
                  {th}
                  <Tbody>
                    {(filteredVehicles.length > 0 &&
                      filteredVehicles.map((vehicle) => {
                        return (
                          <Tr key={vehicle.name}>
                            <Th scope="row">{vehicle.name}</Th>
                            <Td>
                              <PrimaryButton
                                isFullWidth
                                rightIcon={<Icon name="arrowRight" />}
                                onClick={() => navigate(`/${station}/vehicle/${vehicle.id}/drive`)}
                              >
                                Select
                              </PrimaryButton>
                            </Td>
                          </Tr>
                        )
                      })) || (
                      <Tr>
                        <Td scope="row"> No vehicles found </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              ) : (
                <TableSkeleton columns={2} rows={5} thead={th} />
              )}
            </Box>
          </Box>
        </Box>
      </Suspense>
    </ErrorBoundary>
  )
}
