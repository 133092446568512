import { useDisclosure, usePrefersColorScheme } from "@einride/hooks"
import {
  Alert,
  Box,
  Card,
  Icon,
  PageLoader,
  Paragraph,
  PrimaryButton,
  Radio,
  SearchInput,
  SecondaryButton,
  Sheets,
  Stack,
  Table,
  TableSkeleton,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Title1,
  Tooltip,
  Tr,
  useColorScheme,
} from "@einride/ui"
import { Logout } from "components/LogoutButton"
import { useApi } from "contexts/ApiContext"
import FuzzySearch from "fuzzy-search"
import React, { Suspense, useEffect, useMemo, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { useNavigate } from "react-router-dom"
import { Station } from "types"
import { TopRightFlexDiv } from "../../components/FlexDiv"
import { LastSeenWidget } from "../../components/LastSeenWidget"

type SagaColorMode = "light" | "dark" | "system"

export const StationView: React.FC = () => {
  const navigate = useNavigate()
  const [stations, setStations] = useState<Station[] | undefined>(undefined)
  const [useSystemColor, setUseSystemColor] = useState(
    localStorage.getItem("preferredColorTheme") === "system" ||
      !localStorage.getItem("preferredColorTheme"),
  )
  const client = useApi()
  const { isOpen, handlers } = useDisclosure(false)
  const [searchTerm, setSearchTerm] = useState("")
  const searcher = new FuzzySearch(stations || [], ["config.name", "config.location"])
  const filteredStations: Station[] = searcher.search(searchTerm)
  const [sortingOrderAsc, setSortingOrderAsc] = useState<boolean>(true)
  const [sortedColumn, setSortedColumn] = useState<keyof Station["config"]>("name")
  const { isOpen: alertIsOpen, handlers: alertHandlers } = useDisclosure(false)

  useEffect(() => {
    function fetchStations(): void {
      client.GetStations().then((stationsResponse) => {
        setStations(
          Object.entries(stationsResponse).map((station) => {
            return station[1]
          }),
        )
      })
    }
    fetchStations()
    const interval = setInterval(fetchStations, 10000)
    return () => clearInterval(interval)
  }, [client])

  useMemo(() => {
    if (sortingOrderAsc) {
      filteredStations?.sort((a, b) =>
        a.config[sortedColumn].localeCompare(b.config[sortedColumn], undefined, {
          numeric: true,
          sensitivity: "base",
        }),
      )
    } else {
      filteredStations?.sort((a, b) =>
        b.config[sortedColumn].localeCompare(a.config[sortedColumn], undefined, {
          numeric: true,
          sensitivity: "base",
        }),
      )
    }
  }, [filteredStations, sortedColumn, sortingOrderAsc])

  const { setColorScheme, colorScheme } = useColorScheme()
  const systemColor = usePrefersColorScheme()

  const handleColorMode = (color: SagaColorMode): void => {
    localStorage.setItem("preferredColorTheme", color)
    if (color === "system") {
      setUseSystemColor(true)
      setColorScheme(systemColor)
      return
    }
    setUseSystemColor(false)
    setColorScheme(color)
  }

  const handleSearchChange = (searchString: string): void => {
    setSearchTerm(searchString)
  }
  const handleSort = (column: keyof Station["config"]): void => {
    if (column === sortedColumn) {
      setSortingOrderAsc(!sortingOrderAsc)
    } else {
      setSortingOrderAsc(true)
    }
    setSortedColumn(column)
  }

  const th = (
    <Thead>
      <Tr color="secondary">
        <Th style={{ cursor: "pointer" }} scope="col" onClick={() => handleSort("name")}>
          Station
          <Icon
            style={{ paddingLeft: "0.5em", paddingRight: 0 }}
            name={sortingOrderAsc ? "chevronUp" : "chevronDown"}
            hidden={sortedColumn !== "name"}
          />
        </Th>
        <Th style={{ cursor: "pointer" }} scope="col" onClick={() => handleSort("location")}>
          Location
          <Icon
            style={{ paddingLeft: "0.5em", paddingRight: 0 }}
            name={sortingOrderAsc ? "chevronUp" : "chevronDown"}
            hidden={sortedColumn !== "location"}
          />
        </Th>
        <Th scope="col">Connected to</Th>
        <Th scope="col">Status</Th>
        <Th scope="col" />
      </Tr>
    </Thead>
  )

  return (
    <ErrorBoundary fallback={<Text>Failed to load stations view</Text>}>
      <Suspense fallback={<PageLoader />}>
        <Box display="flex">
          <TopRightFlexDiv>
            <SecondaryButton rightIcon={<Icon name="warning" />} onClick={alertHandlers.open}>
              Using release 25 or later?
            </SecondaryButton>
            <SecondaryButton onClick={handlers.open}>Settings</SecondaryButton>
            <Logout>Log out</Logout>
          </TopRightFlexDiv>
          <Box
            alignItems="flex-start"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            padding="sm"
            height="100vh"
            width="100vw"
          >
            <Title1>Select station</Title1>
            <Box padding="sm" height="100vh" width="100%">
              <SearchInput
                autoFocus
                wrapperProps={{ width: "100%" }}
                aria-label="searchStation"
                placeholder="Search stations"
                value={searchTerm}
                onInputChange={handleSearchChange}
              />
              {stations ? (
                <Table>
                  {th}
                  <Tbody>
                    {(filteredStations.length > 0 &&
                      filteredStations.map((station) => {
                        return (
                          <Tr key={station.config.id}>
                            <Td scope="row">
                              <Tooltip hint content={station.config.id}>
                                {station.config.name}
                              </Tooltip>
                            </Td>
                            <Td>{station.config.location}</Td>
                            <Td>{station?.state?.connected_to?.split("/").at(-1) || "Idle"}</Td>
                            <Td>
                              <LastSeenWidget time={station.state.time} />
                            </Td>
                            <Td>
                              <PrimaryButton
                                isFullWidth
                                rightIcon={<Icon name="arrowRight" />}
                                onClick={() => navigate(`/${station.config.name}/vehicle`)}
                              >
                                Select
                              </PrimaryButton>
                            </Td>
                          </Tr>
                        )
                      })) || (
                      <Tr>
                        <Td scope="row"> No stations found </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              ) : (
                <TableSkeleton columns={4} rows={5} thead={th} />
              )}
            </Box>
          </Box>

          <Sheets
            style={{ width: "360px" }}
            closeHandler={handlers.close}
            isOpen={isOpen}
            navigationAction={{
              "aria-label": "close-path-modal",
              icon: "xMark",
              onClick: handlers.close,
            }}
          >
            <Stack gap="sm">
              <Card background="secondary">
                <Paragraph>Appearance</Paragraph>
                <Radio
                  name="appearance"
                  checked={colorScheme === "light" && !useSystemColor}
                  onCheckedChange={() => handleColorMode("light")}
                >
                  Light
                </Radio>
                <Radio
                  name="appearance"
                  checked={colorScheme === "dark" && !useSystemColor}
                  onCheckedChange={() => handleColorMode("dark")}
                >
                  Dark
                </Radio>
                <Radio
                  name="appearance"
                  checked={useSystemColor}
                  onCheckedChange={() => handleColorMode("system")}
                >
                  System Default
                </Radio>
              </Card>
            </Stack>
          </Sheets>
          <Alert
            closeHandler={alertHandlers.close}
            isOpen={alertIsOpen}
            title="Select Operator Interface Version"
            description="You are currently using the v1 version of the operator interface,
         this version is only usuable with release 24.1 or below.
         If you are using a later version please switch to operator interface v2."
            primaryAction={{
              children: "Take me to v2",
              onClick: () => {
                window.location.href = "https://v2.ro.einride.systems/"
              },
            }}
            secondaryAction={{ children: "Continue using v1", onClick: alertHandlers.close }}
          />
        </Box>
      </Suspense>
    </ErrorBoundary>
  )
}
