import { useColorScheme } from "@einride/ui"
import { FlagCheckered } from "@emotion-icons/boxicons-solid"
import bbox from "@turf/bbox"
import { FeatureCollection, Position } from "geojson"
import React, { useEffect, useRef, useState } from "react"
import { Layer, Map, MapRef, Marker, Source } from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"

type Props = {
  geojson?: FeatureCollection
}

const dataSourceId = "data"
const positionSourceId = "position"
const MAPBOX_TOKEN =
  "pk.eyJ1IjoiZWlucmlkZS1wb3J0YWwiLCJhIjoiY2syNGp6OXl6MGVwYTNxcDloaDJybmF6OSJ9.Qi4aTN6veQF-MdHv0_QJ7w"

export const GeoJSONMap: React.FC<Props> = ({ geojson }) => {
  const mapContainer = useRef<MapRef>()
  const { colorScheme } = useColorScheme()
  const [goalFeature, setGoalFeature] = useState<Position | null>(null)
  const [zoom, setZoom] = useState(1)
  const map = mapContainer.current

  if (map) {
    map.on("zoom", () => {
      setZoom(map.getZoom())
    })
  }

  useEffect(() => {
    if (!map || !geojson) {
      return
    }
    const lastSection = geojson.features.findLast(
      // Paths without geofence have features with id "PathSection"
      // Paths with geofence have features with ids "Reference", "Left" and "Right"
      (item) => item.id === "Reference" || item.id === "PathSection",
    )
    if (lastSection && lastSection.geometry.type === "LineString") {
      setGoalFeature(lastSection.geometry.coordinates.at(-1) ?? null)
    }
    const [minLng, minLat, maxLng, maxLat] = bbox(geojson)
    mapContainer.current?.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      { padding: 100 },
    )
  }, [geojson, map])

  return (
    <Map
      initialViewState={{
        longitude: 0,
        latitude: 0,
        zoom: 1,
      }}
      mapboxAccessToken={MAPBOX_TOKEN}
      mapStyle={mapStyleConfig[colorScheme]}
      ref={(el) => {
        // @ts-ignore
        mapContainer.current = el
      }}
      style={{ height: "100vh", width: "100%" }}
    >
      {geojson && <Source type="geojson" id={dataSourceId} data={geojson} />}
      {geojson && goalFeature && zoom > 15 && (
        <Marker
          longitude={goalFeature[0]}
          latitude={goalFeature[1]}
          anchor="bottom-left"
          style={{ width: "24px" }}
          scale={0.5}
        >
          <FlagCheckered />
        </Marker>
      )}
      <Layer
        id="datalayer"
        type="line"
        source={dataSourceId}
        layout={{
          "line-join": "round",
          "line-cap": "round",
        }}
        paint={{
          "line-color": ["get", "stroke"],
          "line-width": 5,
        }}
      />
      <Layer
        id="positionLayer"
        type="symbol"
        source={positionSourceId}
        layout={{
          "icon-image": "pod",
          "icon-size": 0.25,
        }}
      />
    </Map>
  )
}

const mapStyleConfig = {
  light: "mapbox://styles/einride-portal/cl9pffnl8003r14vqtdvg5val",
  dark: "mapbox://styles/einride-portal/cl9pfirmr000u14molltrlf3y",
}
