import {
  Banner,
  Box,
  Caption,
  Card,
  Group,
  HorizontalLayout,
  Icon,
  IconButton,
  PrimaryButton,
  SearchInput,
  SecondaryButton,
  Select,
  Sheets,
  Stack,
  TertiaryButton,
  Text,
  Textarea,
  Title1,
  Title2,
  Tooltip,
} from "@einride/ui"
import { TextareaProps } from "@einride/ui/dist/components/controls/textareas/Textarea/Textarea"
import { PlugDisconnected } from "@emotion-icons/fluentui-system-regular"
import { BottomMidFlexDiv, TopRightFlexDiv } from "components/FlexDiv"
import { GeoJSONMap } from "components/Map"
import { useApi } from "contexts/ApiContext"
import FuzzySearch from "fuzzy-search"
import { FeatureCollection, LineString, Point } from "geojson"
import React, { Suspense, useEffect, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { useNavigate, useParams } from "react-router-dom"
import { NewPath, NewSite } from "types"
import {
  PathLength,
  PathMaxSpeed,
  VerifyGeoJSONFeatureCollection,
} from "views/choosePath/PathComponents"

export const ChoosePathView = (): React.JSX.Element => {
  const { station, vehicle } = useParams()
  if (station === undefined) {
    throw new Error("station cannot be undefined")
  }
  if (vehicle === undefined) {
    throw new Error("vehicle cannot be undefined")
  }

  const client = useApi()
  const navigate = useNavigate()

  const [paths, setPaths] = useState<NewPath[]>([])

  const [sites, setSites] = useState<NewSite[]>([])
  useEffect(() => {
    client.GetSites().then((s) => {
      if (s.length > 0) {
        setSites(s)
        setSelectedSite(s[0].name)
      }
    })
  }, [client])

  const [selectedPath, setSelectedPath] = useState<NewPath>()
  const [sendPathStatus, setSendPathStatus] = useState("")
  const [sendPathSuccess, setSendPathSuccess] = useState<boolean | undefined>(undefined)
  const [localGeo, setLocalGeo] = useState<FeatureCollection>()
  const [localPath, setLocalPath] = useState()
  const [selectedSite, setSelectedSite] = useState<string>("")
  const [isConnected, setIsConnected] = useState<boolean>(false)
  const [isDisconnecting, setIsDisconnecting] = useState(false)
  const [isConnecting, setIsConnecting] = useState(false)
  const [pathSheetOpen, setPathSheetOpen] = useState(false)
  const [customPathText, setCustomPathText] = useState("")
  const [customPathTextStatus, setCustomPathTextStatus] =
    useState<TextareaProps["status"]>("neutral")
  const [customPathTextMessage, setCustomPathTextMessage] = useState("")
  const [feedbackText, setFeedbackText] = useState("")
  const [feedbackSuccess, setFeedbackSuccess] = useState<boolean | undefined>(undefined)
  const [connectionErrorStatus, setConnectionErrorStatus] = useState("")
  const [connectionError, setConnectionError] = useState<boolean>(false)

  function handleSelectSite(input: string): void {
    setSelectedSite(input)
    setSelectedPath(undefined)
  }

  function handleSelectPath(path: NewPath): void {
    client.GetPath(path.name).then((p) => setSelectedPath(p))
  }

  useEffect(() => {
    client.GetPaths(selectedSite).then((p) => {
      setPaths(p)
    })
  }, [selectedSite, client])

  const [pathsSearchTerm, setPathsSearchTerm] = useState("")
  const searcher = new FuzzySearch(paths || [], ["sub_site", "display_name"], { sort: true })
  const filteredPaths: NewPath[] = searcher.search(pathsSearchTerm)
  const handlePathSearch = (searchString: string): void => {
    setPathsSearchTerm(searchString)
  }

  const handleAssignPath = (): void => {
    if (!sites || !selectedPath?.path) {
      return
    }
    client
      .SetPath(station, JSON.parse(atob(selectedPath?.path)))
      .then(() => {
        setSendPathStatus("Successfully assigned path to vehicle")
        setSendPathSuccess(true)
      })
      .catch((reason) => {
        reason.text().then((errMsg: string) => {
          setSendPathStatus(`Failed to set path: ${errMsg}`)
          setSendPathSuccess(false)
        })
      })
    setTimeout(() => {
      setSendPathSuccess(undefined)
    }, 5000)
  }

  useEffect(() => {
    if (sites && !selectedSite) {
      setSelectedSite(Object.keys(sites)[0])
    }
  }, [sites, selectedSite])

  const handleSheetClose = (): void => {
    setPathSheetOpen(false)
    setLocalGeo(undefined)
    setFeedbackText("")
  }

  const handleTextAreaChange = (text: string): void => {
    setCustomPathText(text)

    try {
      const lp = JSON.parse(text)
      VerifyGeoJSONFeatureCollection(lp)
      setLocalPath(lp)
      setLocalGeo(lp)
      setCustomPathTextStatus("success")
      setCustomPathTextMessage("")
      client.ValidatePath(text).then((response) => {
        if (response.status !== 200) {
          response.text().then((respBody) => {
            setCustomPathTextMessage(respBody)
            setCustomPathTextStatus("fail")
          })
        }
      })
    } catch (err: unknown) {
      // @ts-ignore
      setCustomPathTextMessage(err.message)
      setCustomPathTextStatus("fail")
    }
    if (text === "") {
      setLocalPath(undefined)
      setLocalGeo(undefined)
      setCustomPathTextStatus("neutral")
    }
  }

  const handleConnect = (): void => {
    setIsConnecting(true)
    setConnectionError(false)
    client.Connect(station, vehicle).then((resp) => {
      if (!resp.ok) {
        setConnectionError(true)
        setIsConnecting(false)
        setConnectionErrorStatus(`Failed to connect: ${resp.status}: ${resp.statusText}`)
        setTimeout(() => {
          setConnectionError(false)
        }, 5000)
      } else {
        setIsConnecting(false)
        setIsConnected(true)
      }
    })
  }

  const handleDisconnect = (): void => {
    setIsDisconnecting(true)
    setConnectionError(false)
    client
      .Disconnect(station, vehicle)
      .then((resp) => {
        if (!resp.ok) {
          setConnectionError(true)
          setConnectionErrorStatus(`Failed to disconnect: ${resp.status}: ${resp.statusText}`)
          setTimeout(() => {
            setConnectionError(false)
          }, 5000)
        } else {
          setIsConnected(false)
        }
      })
      .finally(() => {
        setIsDisconnecting(false)
      })
  }

  const onDragoverHandler = (event: React.DragEvent): void => {
    event.stopPropagation()
    event.preventDefault()
    const reader = new FileReader()
    reader.onload = (e) => {
      if (e?.target?.result) {
        handleTextAreaChange(e?.target?.result as string)
      }
    }
    const file = event.dataTransfer.files[0]
    if (["application/geo+json", "application/json"].includes(file.type)) {
      reader.readAsText(file, "UTF-8")
    }
  }

  return (
    <ErrorBoundary fallback={<Text>Failed to load paths view</Text>}>
      <Box display="flex">
        <TopRightFlexDiv>
          <IconButton
            aria-label="goBack"
            color="positive"
            icon="arrowLeft"
            onClick={() => {
              if (isConnected) {
                handleDisconnect()
              }
              navigate(-1)
            }}
          >
            {" "}
          </IconButton>
          <PrimaryButton
            hidden={!isConnected}
            color="positive"
            rightIcon={<PlugDisconnected />}
            onClick={handleDisconnect}
            isLoading={isDisconnecting}
          >
            {isConnected ? `Disconnect from ${station}` : "Back"}
          </PrimaryButton>
        </TopRightFlexDiv>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          width="35vw"
          maxWidth="450px"
          padding="sm"
          height="100vh"
          background="secondary"
          gap="sm"
        >
          <Stack gap="none" justifyContent="flex-start" style={{ overflowY: "auto" }}>
            <Group gap="xs">
              <Title1>Control {vehicle.toUpperCase()}</Title1>
              <PrimaryButton
                isFullWidth
                rightIcon={isConnected ? <Icon name="checkmark" /> : <Icon name="arrowRight" />}
                disabled={isConnected || isConnecting}
                isLoading={isConnecting}
                onClick={handleConnect}
              >
                {isConnected ? `Connected to ${station}` : `Connect to ${station}`}
              </PrimaryButton>
              <Tooltip
                content={!selectedPath ? "select a path" : ""}
                disabled={!!selectedSite && !!selectedPath}
              >
                <PrimaryButton
                  isFullWidth
                  rightIcon={<Icon name="arrowRight" />}
                  disabled={!selectedSite || !selectedPath}
                  onClick={handleAssignPath}
                >
                  Assign vehicle path
                </PrimaryButton>
              </Tooltip>
              <Stack width="100%">
                <Select
                  value={selectedSite}
                  label="Select Site"
                  onChange={(e) => {
                    handleSelectSite(e.target.value)
                  }}
                >
                  {!!sites && sites.length > 0
                    ? sites.map((s) => {
                        return (
                          <option key={s.name} value={s.name}>
                            {s.display_name}
                          </option>
                        )
                      })
                    : null}
                </Select>
              </Stack>
            </Group>
            <Stack gap="xs" style={{ overflowY: "auto" }}>
              <Text color="secondary">Select Vehicle Path</Text>
              <SearchInput
                value={pathsSearchTerm}
                wrapperProps={{ width: "100%" }}
                placeholder="Search paths"
                aria-label="search paths"
                onInputChange={(searchString) => handlePathSearch(searchString)}
              />

              <Group gap="none" style={{ overflowY: "auto" }}>
                <Stack gap="xs" justifyContent="flex-start">
                  {filteredPaths && filteredPaths.length > 0 ? (
                    filteredPaths.map((value) => {
                      return (
                        <Card
                          background={value.name === selectedPath?.name ? "focus" : "secondary"}
                          key={value.name}
                          onClick={() => handleSelectPath(value)}
                        >
                          <Group justifyContent="space-between">
                            <Group gap="none" maxWidth="85%">
                              <Text style={{ wordBreak: "break-word", textOverflow: "ellipsis" }}>
                                {value.display_name}
                              </Text>
                              <Caption color="secondary">{value.sub_site}</Caption>
                            </Group>
                            <Group alignItems="center">
                              {value.name === selectedPath?.name && <Icon name="checkmark" />}
                            </Group>
                          </Group>
                        </Card>
                      )
                    })
                  ) : (
                    <Text>No matching paths found</Text>
                  )}
                </Stack>
              </Group>
            </Stack>
          </Stack>
          <Stack>
            <TertiaryButton
              isFullWidth
              onClick={() => {
                setPathSheetOpen(true)
              }}
            >
              Send unverified paths
            </TertiaryButton>
          </Stack>
        </Box>
        {sendPathSuccess !== undefined && (
          <BottomMidFlexDiv>
            <Banner status={sendPathSuccess ? "success" : "fail"}> {sendPathStatus}</Banner>
          </BottomMidFlexDiv>
        )}
        {connectionError && (
          <BottomMidFlexDiv>
            <Banner status="fail"> {connectionErrorStatus}</Banner>
          </BottomMidFlexDiv>
        )}
        <GeoJSONMap geojson={(selectedPath && JSON.parse(atob(selectedPath.path))) || localGeo} />
        <Sheets
          closeHandler={handleSheetClose}
          isOpen={pathSheetOpen}
          navigationAction={{
            "aria-label": "close-path-modal",
            icon: "xMark",
            onClick: handleSheetClose,
          }}
        >
          <Stack height="100%" justifyContent="space-between" gap="xs">
            <Stack gap="xs">
              <Title2>CAUTION!</Title2>
              <Text>
                The paths sent through this box has not been verified by Einride. The vehicle could
                perform unexpected maneuvers.
              </Text>
              <Textarea
                onDrop={(event) => onDragoverHandler(event)}
                style={{ height: "50vh" }}
                label="GeoJSON file contents"
                placeholder="To use a local path, drag and drop the GeoJSON file here or paste the contents of the file"
                onChange={(event) => {
                  handleTextAreaChange(event.target.value)
                }}
                value={customPathText}
                status={customPathTextStatus}
                message={customPathTextStatus === "fail" && customPathTextMessage}
              />
            </Stack>
            {localGeo !== undefined && customPathTextStatus !== "fail" ? (
              <HorizontalLayout gap="lg">
                <PathLength path={localGeo as FeatureCollection<LineString | Point>} />
                <PathMaxSpeed path={localGeo as FeatureCollection<LineString | Point>} />
              </HorizontalLayout>
            ) : null}
            <Group>
              <PrimaryButton
                disabled={!localPath || customPathTextStatus === "fail"}
                rightIcon={<Icon name="loupe" />}
                onClick={() => {
                  setLocalGeo(localPath)
                  setSelectedPath(undefined)
                  setPathSheetOpen(false)
                  setFeedbackText("")
                }}
              >
                Preview path
              </PrimaryButton>
              <SecondaryButton
                disabled={!localPath || customPathTextStatus === "fail"}
                rightIcon={<Icon name="arrowRight" />}
                onClick={() => {
                  setLocalGeo(undefined)
                  setSelectedPath(undefined)
                  if (localPath) {
                    client.SetPath(station, localPath).then(
                      () => {
                        setFeedbackText("Path successfully sent")
                        setFeedbackSuccess(true)
                      },
                      (reason) => {
                        reason.text().then((data: string) => {
                          setFeedbackText(data)
                          setFeedbackSuccess(false)
                        })
                      },
                    )
                  }
                }}
              >
                Assign local path
              </SecondaryButton>
            </Group>
            {!!feedbackText && (
              <Banner status={feedbackSuccess ? "success" : "fail"} title={feedbackText} />
            )}
          </Stack>
        </Sheets>
      </Box>
    </ErrorBoundary>
  )
}
